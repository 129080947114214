export const environment = {
  production: false,
  apiUrl: 'https://api-dev.elenasy.com/api/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyDcrBvUQZfsTJkSf6mNouPfTwTLlMh5K-4',
    authDomain: 'elenasy-13e21.firebaseapp.com',
    projectId: 'elenasy-13e21',
    storageBucket: 'elenasy-13e21.appspot.com',
    messagingSenderId: '247612237338',
    appId: '1:247612237338:web:38610aed312af3514093a3',
  },
  VAPID_KEY:
    'BAbAr90fxYaE48Kw_j7yQ1DIxyPuSGehvLCQLAMnOTa91OG79rlLxkHb4WoXbEwHns-B8JpgfNx6JkiT4v8gilo',
};
